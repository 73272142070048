/**
 * ValueObjectの基本型
 */
export abstract class BaseValue<V> {
  constructor(protected value: V) {}

  getValue(): V {
    return this.value;
  }
}
