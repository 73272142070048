import firebase from 'firebase/app';
import 'firebase/auth';

export const initializeFirebase = () => {
    fetch('/__/firebase/init.json').then(async response => {
        firebase.initializeApp(await response.json());
    })
};

const provider = new firebase.auth.GoogleAuthProvider();
provider.addScope('https://www.googleapis.com/auth/drive.file');

export const authProvider = provider;

export const getAuthorizationHeader = (userCredential: firebase.auth.UserCredential, authToken: string) => {
    let credential = userCredential.credential as firebase.auth.OAuthCredential;
    let googleAccessToken = credential.accessToken;
    return {
        'Authorization': `Bearer ${authToken}`,
        'google-access-token': googleAccessToken
    };
};
