import * as React from "react";

interface UploadStatus {
    uploadProgress: Number;
}

export const UploadStatus: React.FunctionComponent<UploadStatus> = ({uploadProgress}) => {
    return (
        <div>
            {
                uploadProgress < 1 &&
                <div>
                  <p>アップロード中です...</p>
                  <progress className="progress is-info" value={uploadProgress.toString()} max="1"/>
                </div>
            }
            {
                uploadProgress >= 1 &&
                <div>
                  <p>処理中です...</p>
                  <progress className="progress is-info"/>
                </div>
            }
        </div>)
};
