import * as React from "react";
import {Modal} from "@graffer-inc/graffer-ui";
import * as firebase from "firebase";
import {authProvider} from "../lib/firebase-auth";

interface props {
    setUserCredential: (user: firebase.auth.UserCredential | null) => void;
    setAuthToken: (authToken: string | null) => void;
}

export const SelectSaveDestinationModal: React.FunctionComponent<props> = ({setUserCredential, setAuthToken}) => {
    const googleSignUp = async () => {
        firebase.auth().signInWithPopup(authProvider).then(async (result) => {
            if (result) {
                setAuthToken(await result.user!.getIdToken());
                setUserCredential(result);
            }
        }).catch((error) => {
            console.log(error);
            alert('GoogleDriveとの連携に失敗しました');
        });

    };
    return (
        <Modal>
            <div className="box has-text-centered">
                <h2 className="title is-size-5">
                    結果を書き出す先のアプリを選択してください。
                </h2>
                <button
                    className="button is-medium"
                    onClick={googleSignUp}
                    style={{marginBottom: "20px"}}>
                    Google Drive
                </button>
                <p>アプリのログイン及び連携設定を行ってください。<br/>
                    署名結果が選択されたアプリに直接保存されます。</p>
            </div>
        </Modal>
    );
};
