import * as React from "react";
import QRCode from "qrcode.react";
import {Modal} from "@graffer-inc/graffer-ui";
import Countdown from "react-countdown";
import {ViewQRcodeOS} from "./view-qrcode-os";
import {SignRequest} from "../domain/sign-request";
import {FeatureFlags, LaunchOrInstallLink} from "./launch-or-install-link";

interface QrCodeModalProps {
  signRequest: SignRequest;
  returnUrlToken?: string;
  message?: string;
  expiredAt: Date;
  onClose: () => void;
}

export const QrCodeModal: React.FunctionComponent<QrCodeModalProps> = ({
                                                                         signRequest,
                                                                         returnUrlToken,
                                                                         expiredAt,
                                                                         onClose,
                                                                       }) =>
  signRequest.displaySignRequest(({encodedSignRequest, signRequestJson}) => (
    <Modal onClose={onClose}>
      <div className="box has-text-centered">
        {ViewQRcodeOS.from().matchType({
          Android: (
            <h2 className="title is-size-5">
              端末内のアプリで署名してください。
            </h2>
          ),
          iPhone: FeatureFlags.linkToAppleStore ? (
            <h2 className="title is-size-5">
              端末内のアプリで署名してください。
            </h2>
          ) : (
            <>
              <h2 className="title is-size-5">
                電子署名アプリで読み込んでください。
              </h2>
              <QRCode value={signRequestJson} />
            </>
          ),
          Other: (
            <>
              <h2 className="title is-size-5">
                電子署名アプリで読み込んでください。
              </h2>
              <QRCode value={signRequestJson} />
            </>
          ),
        })}
        <div className="columns">
          <Countdown
            date={expiredAt}
            renderer={({completed}) =>
              completed ? (
                <div className="column">
                  <p>
                    有効期限が切れました。申請フォームに戻り、再度QRコードを発行してください。
                  </p>
                </div>
              ) : (
                <div style={{ paddingTop: "24px" }}>
                  <LaunchOrInstallLink
                    encodedSignRequest={encodedSignRequest}
                    returnUrlToken={returnUrlToken}
                  />
                </div>
              )
            }
          />
        </div>
      </div>
    </Modal>
  ));
