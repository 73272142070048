import * as React from "react";
import * as firebase from 'firebase/app';

type Props = {
    userCredential: firebase.auth.UserCredential | null;
};

export const GoogleSignInStatus: React.FC<Props> = ({userCredential}) => {
    return (
        <div
            style={{margin: "20px"}}>
            {userCredential && userCredential.user &&
            <p>
              GoogleDrive連携中です<br/>
                {userCredential.user.email}
            </p>
            }
        </div>
    )
};
