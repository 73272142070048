import MobileDetect from "mobile-detect";
import {BaseType} from "../domain/base-type";

type ViewQRcodeOSLiterals = "Android" | "iPhone" | "Other";

export class ViewQRcodeOS extends BaseType<ViewQRcodeOSLiterals> {
  static from() {
    const mobileDetect = new MobileDetect(window.navigator.userAgent);
    if (mobileDetect.os() === "AndroidOS") {
      return new ViewQRcodeOS("Android");
    }
    if (mobileDetect.os() === "iOS") {
      return new ViewQRcodeOS("iPhone");
    }
    return new ViewQRcodeOS("Other");
  }
}
