import * as React from "react";

type Props = {
  className?: string;
};

export const AppleStoreLink: React.FC<Props> = ({ className, children }) => (
  <a className={className} href="#apple-store-link">
    {children}
  </a>
);
