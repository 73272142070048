import * as React from "react";
import {Modal} from "@graffer-inc/graffer-ui";
import {GoogleDriveResult} from "../domain/session-subscribe";

interface DownloadModalProps {
    result: GoogleDriveResult;
    onClose: () => void;
}

export const GoogleDriveDownloadModel: React.FunctionComponent<DownloadModalProps> = ({result, onClose,}) => {
    return (
        <Modal onClose={onClose}>
            <div className="box has-text-centered">
                <h2 className="title is-size-5">
                    電子署名が完了しました。
                </h2>
                <a
                    href={result.alternateLink}
                    download="signResult.json">
                    <button
                        className="button is-success">GoogleDriveで確認する
                    </button>
                </a>
            </div>
        </Modal>
    );
};
