import MobileDetect from "mobile-detect";
import {BaseType} from "../domain/base-type";

type LaunchOrInstallLinkSwitcherLiterals =
  | "AndroidChrome"
  | "AndroidOther"
  | "iPhoneSafari"
  | "iPhoneChrome"
  | "iPhoneOther"
  | "Phone"
  | "Other";

export class LaunchOrInstallLinkSwitcher extends BaseType<
  LaunchOrInstallLinkSwitcherLiterals
> {
  static from() {
    const mobileDetect = new MobileDetect(window.navigator.userAgent);
    if (mobileDetect.os() === "AndroidOS") {
      if (mobileDetect.userAgent() === "Chrome") {
        return new LaunchOrInstallLinkSwitcher("AndroidChrome");
      }
      return new LaunchOrInstallLinkSwitcher("AndroidOther");
    }
    if (mobileDetect.os() === "iOS") {
      if (mobileDetect.userAgent() === "Safari") {
        return new LaunchOrInstallLinkSwitcher("iPhoneSafari");
      }
      if (mobileDetect.userAgent() === "Chrome") {
        return new LaunchOrInstallLinkSwitcher("iPhoneChrome");
      }
      return new LaunchOrInstallLinkSwitcher("iPhoneOther");
    }
    if (mobileDetect.phone()) {
      return new LaunchOrInstallLinkSwitcher("Phone");
    }
    return new LaunchOrInstallLinkSwitcher("Other");
  }
}
