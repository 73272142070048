import {BaseTypeLiterals} from "./base-type";
import * as Axios from "axios";

type SessionSubscribeLiterals =
  | "OK"
  | "PENDING"
  | "NG";

const interval = 3000;
const sleep = () => new Promise((resolve) => setTimeout(resolve, interval));

export type Result = {
  status: SessionSubscribeLiterals;
}

export type GoogleDriveResult = Result & {
  id: string
  etag: string
  selfLink: string
  webContentLink: string
  alternateLink: string
  embedLink: string
  title: string
}

export function sessionSubscribe<E extends Result>(token: string, matcher: BaseTypeLiterals<SessionSubscribeLiterals, (result: E) => void>): () => void {
  let loop = true;
  (async () => {
    let result: E;
    while (loop) {
      try {
        const {data} = await Axios.default.post<E>("/api/v1/get-google-drive-result", {
          token: token,
        });
        result = data;
        matcher[result.status](result);
        if (result.status !== "PENDING") {
          break;
        } else {
          await sleep();
        }
      } catch (e) {
        // 次のリクエストで自動でリトライされるので、ユーザーには見せない
      }
    }
  })();
  return () => (loop = false);
}
