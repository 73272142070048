import { BaseValue } from "./base-value";

export type BaseTypeLiterals<L extends string, R> = { [key in L]: R };

/**
 * StringLiteralsをベースにした選択可能なValueObjectの基本型
 */
export abstract class BaseType<TypeLiterals extends string> extends BaseValue<
  TypeLiterals
> {
  equals(type: TypeLiterals) {
    return this.value === type;
  }
  typeEquals<T extends BaseType<TypeLiterals>>(target: T): boolean {
    return this.value === target.value;
  }
  matchType<R>(matcher: BaseTypeLiterals<TypeLiterals, R>): R {
    return matcher[this.value];
  }
}
