import {Base64} from "js-base64";
import {BaseValue} from "./base-value";

const BASE_URL_FOR_SIGNATURE = window.location.origin;

export type Protocol = "graffer-identity-sign";
export type Version = "v1";

export type SignTargetDataType =
  | "PDF" // PDF形式のデータに対する署名
  | "CLOUD-GOV"; // Graffer 電子申請での申請データに対する署名
export type ResultDestinationType = "https";


type SignRequestType = {
  protocol: Protocol;
  version: Version;
  requestId: string;
  target: {
    type: SignTargetDataType;
    uri: string;
  };
  resultDestination: {
    type: ResultDestinationType;
    destination: string;
  };
};

export class SignRequest extends BaseValue<SignRequestType> {

  static create(token: string) {
    return new SignRequest({
      protocol: "graffer-identity-sign",
      version: "v1",
      requestId: token,
      target: {
        type: "PDF",
        uri: `${BASE_URL_FOR_SIGNATURE}/api/v1/fetch-signed-data?token=${token}`,
      },
      resultDestination: {
        type: "https",
        destination: `${BASE_URL_FOR_SIGNATURE}/api/v1/complete-signature`,
      },
    });
  }

  getJSONString(): string {
    return JSON.stringify(this.getValue());
  }

  displaySignRequest<R>(
    callback: (param: {
      encodedSignRequest: string;
      signRequestJson: string;
    }) => R
  ): R {
    const signRequestJson = this.getJSONString();
    return callback({
      encodedSignRequest: Base64.encodeURI(signRequestJson),
      signRequestJson: signRequestJson,
    });
  }
}
