import React, {useState, useEffect} from 'react';
import Dropzone from 'react-dropzone'
import moment from "moment";
import * as firebase from 'firebase/app';
import 'firebase/auth';

import * as Axios from "axios";
import {Header} from "@graffer-inc/graffer-ui";
import {QrCodeModal} from "./components/qr-modal";
import {SignRequest} from "./domain/sign-request";
import {GoogleDriveResult, sessionSubscribe} from "./domain/session-subscribe";
import {getAuthorizationHeader, initializeFirebase} from "./lib/firebase-auth";
import {GoogleSignInStatus} from "./components/google-sign-status";
import {GoogleDriveDownloadModel} from "./components/google-drive-download-modal";
import {UploadStatus} from "./components/upload-status";
import {SelectSaveDestinationModal} from "./components/select-save-destination-modal";

type TokenType = {
  token: string
  expireAt: Date
}

initializeFirebase();

const App = () => {

  const [isUploading, setIsUploading] = useState(false);
  const [signRequest, setSignRequest] = useState<SignRequest | null>(null);
  const [fetchToken, setFetchToken] = useState<TokenType | null>();
  const [authToken, setAuthToken] = useState<string | null>(null);
  const [googleDriveResult, setGoogleDriveResult] = useState<GoogleDriveResult | null>(null);
  const [userCredential, setUserCredential] = useState<firebase.auth.UserCredential | null>(null);
  const [upLoadProgress, setUpLoadProgress] = useState<Number>(0);

  useEffect(() => {
    if (fetchToken) {
      if (userCredential) {
        return sessionSubscribe<GoogleDriveResult>(fetchToken.token, {
          NG: (result) => {
            setSignRequest(null);
            alert("署名に失敗しました。お手数ですが最初からやり直してください。");
          },
          OK: (result) => {
            setSignRequest(null);
            setGoogleDriveResult(result)
          },
          PENDING: (result) => {
          }
        });
      }
    }
  }, [signRequest, fetchToken, userCredential]);

  return (<div className="c-content-wrapper">
    <Header/>
    <section className="hero is-fullheight">
      <div className="hero-body">
        <div className="container has-text-centered">
          <div className="columns">
            <div className="column is-4"/>
            <div className="column is-4">
              <div className="box has-text-centered" style={{
                marginTop: "-60px"
              }}
              >
                <h1 className="title">
                  Graffer Sign
                </h1>
                <GoogleSignInStatus userCredential={userCredential}/>
                { userCredential && authToken &&
                <Dropzone accept="application/pdf" onDrop={acceptedFiles => {
                  const fileReader = new FileReader();
                  const fileDropped = acceptedFiles[0];
                  fileReader.onload = function () {
                    const dataUri = this.result as string;
                    const fileDataURL = dataUri.split(",")[1];
                    setIsUploading(true);
                    Axios.default.post<{
                      fetchToken: TokenType,
                      signToken: TokenType,
                    }>("/api/v1/upload-sign-target", {
                      data: fileDataURL,
                    },{
                      headers: getAuthorizationHeader(userCredential, authToken),
                      onUploadProgress: (progressEvent) => {
                        setUpLoadProgress(progressEvent.loaded / progressEvent.total)
                      }
                    }).then(result => {
                      setSignRequest(SignRequest.create(result.data.signToken.token));
                      setFetchToken(result.data.fetchToken);
                      setIsUploading(false);
                      setUpLoadProgress(0);
                    }).catch(e => {
                      alert(JSON.stringify(e));
                      setIsUploading(false);
                      setUpLoadProgress(0);
                    });
                  };
                  fileReader.readAsDataURL(fileDropped);

                }}>
                  {({getRootProps, getInputProps}) => (
                    <section style={{
                      border: "dashed 1px",
                      padding: "36px"
                    }}>
                      {!isUploading &&
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p>マイナンバーカードで電子署名を付与したいファイルを選択してください。</p>
                        <button type="button" className="button is-info">
                          選択
                        </button>
                      </div>}
                      {isUploading && <UploadStatus uploadProgress={upLoadProgress}/>}
                    </section>
                  )}
                </Dropzone>
                    }
              </div>
            </div>
            <div className="column is-4"/>
          </div>
        </div>
      </div>
    </section>
    {
      !userCredential && (
          <SelectSaveDestinationModal setUserCredential={setUserCredential} setAuthToken={setAuthToken}/>
      )
    }
    {
      signRequest && (
        <QrCodeModal signRequest={signRequest}
                     expiredAt={moment().add(1, "hours").toDate()}
                     onClose={() => {
                       setSignRequest(null);
                       setFetchToken(null)
                     }}/>
      )
    }
    {
      googleDriveResult && (
          <GoogleDriveDownloadModel result={googleDriveResult}
                                    onClose={() => {
                                      setGoogleDriveResult(null);
                                    }}/>
      )
    }
  </div>)
};

export default App;
