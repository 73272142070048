import * as React from "react";
import {LaunchOrInstallLinkSwitcher} from "./launch-or-install-link-switcher";
import {AppleStoreLink} from "./apple-store-link";

type Props = {
  encodedSignRequest: string;
  returnUrlToken?: string;
};

export const FeatureFlags = {
  linkToAppleStore: true,
};

const getReturnUri = (token: string | undefined) => {
  // staging, production は smart-apply.graffer.jp のドメインの場合もあるので、local環境のみ環境変数を参照する
  const origin = window.location.origin;
  const path = window.location.pathname;
  return `${origin}${path.endsWith("/") ? path.slice(0, -1) : path}${
    token ? "/" + token : ""
    }`;
};

export const LaunchOrInstallLink: React.FC<Props> = ({
                                                       encodedSignRequest,
                                                       returnUrlToken,
                                                     }) => {
  const signRequest = `sign_request=${encodedSignRequest}`;
  const returnUri = `return_uri=${encodeURIComponent(
    getReturnUri(returnUrlToken)
  )}`;
  const getQS = (browser: string) =>
    `browser=${browser}&${returnUri}&${signRequest}`;
  return LaunchOrInstallLinkSwitcher.from().matchType({
    AndroidChrome: (
      <div className="column">
        <a
          className="button"
          href={`intent://sign?${getQS(
            "chrome"
          )}#Intent;scheme=graffer-sign;package=jp.graffer.jpkimobile;end`}
        >
          端末内のアプリで署名する
        </a>
      </div>
    ),
    AndroidOther: (
      <div className="column">
        <a
          className="button"
          href={`intent://sign?${getQS(
            "other"
          )}#Intent;scheme=graffer-sign;package=jp.graffer.jpkimobile;end`}
        >
          端末内のアプリで署名する
        </a>
      </div>
    ),
    iPhoneSafari: FeatureFlags.linkToAppleStore ? (
      <>
        <div className="column">
          <a
            className="button"
            href={`graffer-sign://sign?${getQS("safari")}`}
          >
            タップしてアプリを起動
          </a>
        </div>
        <div className="column">
          <AppleStoreLink className="button">
            アプリのインストールはこちら
          </AppleStoreLink>
        </div>
      </>
    ) : (
      <></>
    ),
    iPhoneChrome: FeatureFlags.linkToAppleStore ? (
      <>
        <div className="column">
          <a
            className="button"
            href={`graffer-sign://sign?${getQS("chrome")}`}
          >
            タップしてアプリを起動
          </a>
        </div>
        <div className="column">
          <AppleStoreLink className="button">
            アプリのインストールはこちら
          </AppleStoreLink>
        </div>
      </>
    ) : (
      <></>
    ),
    iPhoneOther: FeatureFlags.linkToAppleStore ? (
      <>
        <div className="column">
          <a className="button" href={`graffer-sign://sign?${signRequest}`}>
            タップしてアプリを起動
          </a>
        </div>
        <div className="column">
          <AppleStoreLink className="button">
            アプリのインストールはこちら
          </AppleStoreLink>
        </div>
      </>
    ) : (
      <></>
    ),
    Phone: FeatureFlags.linkToAppleStore ? (
      <div className="column">
        マイナンバーカードでの電子署名にはiOS /
        Androidが搭載されたスマートフォンが必要となります。
        マイナンバーカードでの電子署名にはAndroidが搭載されたスマートフォンが必要となります。
      </div>
    ) : (
      <div className="column">
        マイナンバーカードでの電子署名にはAndroidが搭載されたスマートフォンが必要となります。
      </div>
    ),
    Other: null,
  });
};
